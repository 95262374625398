<script lang="ts">
  export let value: string;
  let clazz: string | undefined = "emoji";
  export { clazz as class };

  import twemoji from "twemoji";
  import { afterUpdate } from "svelte";

  let emojiHtml: string | undefined = undefined;

  afterUpdate(() => {
    emojiHtml = twemoji.parse(value, {
      folder: "svg",
      ext: ".svg",
      className: clazz,
    });
  });
</script>

<span>{@html emojiHtml}</span>
