<script lang="ts">
  import { shuffleArray } from "./utils";
  import { createEventDispatcher } from "svelte";

  import type { Record } from "./types";
  import { Event } from "./types";
  export let record: Record;
  export let disabled: boolean = false;

  const dispatch = createEventDispatcher();

  const options = ["i", "í", "y", "ý"];

  let { question, answer } = record;

  let selectedAnswer: string | undefined = undefined;

  let questionToShow = question;

  const UNDERSCORES_REGEXP = /\_/g;
  let missingCharIndex = question.search(UNDERSCORES_REGEXP);

  const EN_SPACE_CHAR = "\u2002";
  let missingChar = EN_SPACE_CHAR;

  function isAnswerCorrect() {
    if (Array.isArray(answer)) {
      return answer.includes(selectedAnswer);
    }

    return selectedAnswer === answer;
  }

  async function handleOptionClick(event) {
    if (disabled) {
      return;
    }

    selectedAnswer = event.target.value;
    missingChar = selectedAnswer;

    const isCorrect = isAnswerCorrect();

    dispatch(Event.Answer, {
      isCorrect,
    });
  }
</script>

<div class="container">
  <div class="quiz">
    {#each questionToShow as char, index}
      {#if index === missingCharIndex}
        <span class="hidden-char">{missingChar}</span>
      {:else}
        {char}
      {/if}
    {/each}
  </div>

  <div class="options">
    {#each shuffleArray(options) as option, index}
      <button
        on:click={handleOptionClick}
        value={option}
        tabindex={index + 1}
        {disabled}
        type="button">{option}</button
      >
    {/each}
  </div>
</div>

<style>
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .quiz {
    text-align: center;
    font-size: 3rem;
    font-weight: 100;
  }

  .hidden-char {
    border: 1px solid var(--bg-color-secondary);
    background-color: var(--bg-color-secondary);
    border-radius: 2px;
  }

  .options {
    margin: 3rem 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  .options button {
    padding: 0.25rem 1rem;
    font-size: 2.5rem;
    border: 2px solid var(--bg-color-secondary);
    max-width: 4rem;
    margin: 0;
  }

  .options button:focus {
    border: 2px solid var(--font-color);
  }

  /* PC and iPad Pro */
  @media only screen and (min-device-width: 64em) {
    .quiz {
      font-size: 4rem;
    }

    .options {
      grid-auto-flow: column;
      gap: 0 2rem;
    }

    .options button {
      font-size: 3.5rem;
    }
  }

  @media (orientation: landscape) {
    .options {
      grid-auto-flow: column;
    }
  }
</style>
