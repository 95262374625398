<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import Badge from "./Badge.svelte";

  const dispatch = createEventDispatcher();
  function handleRepeatClick() {
    dispatch("repeat");
  }
</script>

<div>
  <p>Hotovo!</p>
  <button on:click={handleRepeatClick} type="button">Další cvičení</button>
</div>

<style>
  div {
    display: flex;
    flex-direction: column;
    gap: 2rem 0;
  }

  p {
    text-align: center;
    font-size: 4rem;
    margin: 0;
  }
</style>
