<script lang="ts">
  import { slide } from "svelte/transition";
  import { quintOut } from "svelte/easing";
  import { writable } from "svelte/store";
  import { persist, localStorage } from "@macfja/svelte-persistent-store";

  import { timeout, sample, shuffleArray, randomNumber } from "./utils";
  import Question from "./Question.svelte";
  import type { QuestionSet, Record } from "./types";
  import Results from "./Results.svelte";
  import QuestionResult from "./QuestionResult.svelte";

  export let questionSets: QuestionSet;
  const questionSetLength = Object.keys(questionSets).length;

  function getRandomQuestionSetName() {
    const rngIndex = randomNumber(questionSetLength);
    return Object.keys(questionSets)[rngIndex];
  }

  let completedQuestionSets = persist(
    writable<string[]>([]),
    localStorage(),
    "souhlasky_completed"
  );

  let currentQuestionSetName = getNextQuestionSetName();

  $: rngQuestionSet = questionSets[currentQuestionSetName];
  $: questions = shuffleArray<Record>(rngQuestionSet);

  const successEmoji = [
    "🙌",
    "✨",
    "🥰",
    "🔥",
    "👍",
    "🤠",
    "🥳",
    "👌",
    "🦸",
    "👍",
    "🥷",
  ];
  const failEmoji = ["🤐", "😬", "🤯", "😕", "😱", "😩", "🧟", "🙅"];

  const slideInTransition = {
    delay: 0,
    duration: 1000,
    easing: quintOut,
  };

  function getResultEmoji(isCorrect: boolean): string {
    return isCorrect
      ? (sample(successEmoji) as string)
      : (sample(failEmoji) as string);
  }

  let emojiToShow = undefined;
  let questionAnswered: boolean = false;
  let canClick = true;
  let isAnswerCorrect = false;

  let currentQuestionIndex = 0;
  $: hasQuestions = currentQuestionIndex < questions.length;

  async function handleAnswer(event) {
    canClick = false;
    const { isCorrect } = event.detail;

    emojiToShow = getResultEmoji(isCorrect);

    questionAnswered = true;
    isAnswerCorrect = isCorrect;
    await timeout(2000);
    questionAnswered = false;
    canClick = true;

    if (isCorrect) {
      currentQuestionIndex += 1;
    }

    if (currentQuestionIndex >= questions.length) {
      // Save completed question set name into store
      $completedQuestionSets = [
        ...$completedQuestionSets,
        currentQuestionSetName,
      ];
    }
  }

  function getNextQuestionSetName() {
    if (!$completedQuestionSets || $completedQuestionSets?.length === 0) {
      return getRandomQuestionSetName();
    }

    if ($completedQuestionSets.length >= questionSetLength) {
      $completedQuestionSets = [];
      return getRandomQuestionSetName();
    }

    let nextQuestionSetName = getRandomQuestionSetName();
    while ($completedQuestionSets.includes(nextQuestionSetName)) {
      nextQuestionSetName = getRandomQuestionSetName();
    }

    return nextQuestionSetName;
  }

  function handleRepeat() {
    currentQuestionSetName = getNextQuestionSetName();

    currentQuestionIndex = 0;
  }
</script>

<main>
  {#if hasQuestions}
    {#key currentQuestionIndex}
      <div transition:slide={slideInTransition}>
        <Question
          record={questions[currentQuestionIndex]}
          on:answer={handleAnswer}
          disabled={!canClick}
        />
      </div>
    {/key}
  {:else}
    <div transition:slide={slideInTransition}>
      <Results on:repeat={handleRepeat} />
    </div>
  {/if}

  {#if questionAnswered}
    <QuestionResult emoji={emojiToShow} isCorrect={isAnswerCorrect} />
  {/if}
</main>

<style>
  main {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    user-select: none;
    margin: 5rem auto;

    min-width: 80vw;
    font-size: 2rem;
  }
</style>
