<script lang="ts">
  import { fly, slide } from "svelte/transition";
  import { quintOut } from "svelte/easing";

  import Emoji from "./Emoji.svelte";
  import Badge from "./Badge.svelte";

  export let emoji: string;
  export let isCorrect: boolean;
</script>

<div
  class="outer-container"
  transition:fly={{
    y: 100,
    delay: 0,
    duration: 2000,
    easing: quintOut,
  }}
>
  <div class="inner-container">
    <div class="badge">
      {#if isCorrect}
        <Badge kind="success">Správně</Badge>
      {:else}
        <Badge kind="error">Špatně</Badge>
      {/if}
    </div>
    <Emoji value={emoji} class="emoji-question-result" />
  </div>
</div>

<style>
  .outer-container {
    position: absolute;
  }

  .inner-container {
    position: relative;
    margin: 25vh auto 0;
    background-color: var(--bg-color-primary);
  }

  .badge {
    margin: 1rem 0;
  }

  :global(.emoji-question-result) {
    height: 40vh;
  }
</style>
