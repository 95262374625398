<script lang="ts">
  type BadgeKind = "success" | "warning" | "error" | "neutral";
  export let kind: BadgeKind = "neutral";
</script>

<div class={kind}>
  <slot />
</div>

<style>
  div {
    color: #111;

    border-radius: 3rem;
    text-align: center;

    padding: 0.5rem 2rem;
    line-height: inherit;
  }

  .success {
    background-color: #01ff70;
  }

  .warning {
    background-color: #ffdc00;
  }

  .error {
    background-color: #ff4136;
  }

  .neutral {
    background-color: #dddddd;
  }
</style>
