// @ts-nocheck
// todo: ts-nocheck due to aan error, when loading json file
import questionSets from "../data/questions.json";
import App from "./App.svelte";
const app = new App({
    target: document.body,
    props: {
        questionSets,
    },
});
export default app;
