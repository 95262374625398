export async function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}
export function randomNumber(max, min = 0) {
    return Math.floor(Math.random() * max + min);
}
export function shuffleArray(arr) {
    const arrCopy = [...arr];
    return arrCopy.sort(() => Math.random() - 0.5);
}
export function shuffleObject(obj) {
    const newObj = {};
    const keys = Object.keys(obj);
    keys.sort(() => Math.random() - 0.5);
    keys.forEach((key, index) => (newObj[index] = obj[key]));
    return newObj;
}
export function sample(arr) {
    const rngIndex = randomNumber(arr.length);
    return arr[rngIndex];
}
export function sampleProperty(obj) {
    const rngIndex = randomNumber(Object.keys(obj).length);
    return obj[rngIndex];
}
